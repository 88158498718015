import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout';
import Seo from '../components/seo';
import Bio from '../components/bio';
import TimeStampSection from '../components/timestamp-section';
import ProjectSection from '../components/project-section';
import MainImage1 from "../../assets/main1.jpeg";
import MainImage2 from "../../assets/main2.jpeg";

function AboutPage({ data }) {
  const metaData = data.site.siteMetadata;
  const { author, about, language } = metaData;
  const { timestamps, projects } = about;
  return (
    <Layout>
      <Seo title="About" />
      <Bio author={author} language={language} />
      <div style={{ backgroundColor: 'transparent', margin: '20px' }}>
        <img style={{ width: 250, height: 280, margin: '20px' }} src={MainImage1} alt="thumbnail" />
        <img style={{ width: 250, height: 280, margin: '20px' }} src={MainImage2} alt="thumbnail" />
      </div>
      <div
        style={{
          backgroundColor: '#f9f9f9',
          padding: '30px',
          borderRadius: '12px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          maxWidth: '800px',
          margin: '40px auto',
          lineHeight: '1.8',
          fontFamily: 'Arial, sans-serif',
        }}
      >
        <h1 style={{ textAlign: 'center', color: '#333', marginBottom: '20px', fontSize: '22px' }}>About Me</h1>

        <p style={{ fontSize: '16px', color: '#555', textAlign: 'justify' }}>
          My name is <strong>Minseok Choi</strong>, and I graduated in{' '}
          <strong>February 2025</strong>
          with a degree in software engineering. I am currently working at a startup and have a
          strong interest in <strong>DevOps</strong> and <strong>cloud-native architectures</strong>
          . I am preparing for <strong>AWS</strong> and <strong>Kubernetes CKA</strong>{' '}
          certifications while learning <strong>Terraform</strong> for infrastructure as code.
        </p>

        <br />

        <h2 style={{ color: '#222', borderBottom: '2px solid #ddd', paddingBottom: '5px', fontSize: '16px' }}>
          Frontend & Backend Development
        </h2>
        <p style={{ fontSize: '16px', color: '#555', textAlign: 'justify' }}>
          In frontend development, I have built user-friendly interfaces using{' '}
          <strong>React</strong>
          &nbsp;and <strong>Vue</strong>, focusing on enhancing user experience. On the backend, I
          specialize in developing high-performance, non-blocking REST APIs using{' '}
          <strong>Spring WebFlux</strong>
          &nbsp;and <strong>Reactor</strong>, along with experience in{' '}
          <strong>MySQL database design and optimization</strong>.
        </p>

        <br />

        <h2 style={{ color: '#222', borderBottom: '2px solid #ddd', paddingBottom: '5px', fontSize: '16px' }}>
          Key Achievements
        </h2>
        <ul style={{ paddingLeft: '20px', color: '#555', fontSize: '16px' }}>
          <li style={{ marginBottom: '10px' }}>
            Implemented <strong>OAuth2-based authentication</strong> and authorization for various
            social login providers, integrating <strong>Redis TTL</strong> for serverless
            access/refresh token management.
          </li>
          <li style={{ marginBottom: '10px' }}>
            Developed batch processing tasks using <strong>Spring Batch</strong>, including
            scheduler-based metric updates.
          </li>
          <li style={{ marginBottom: '10px' }}>
            Optimized API response times and database queries through{' '}
            <strong>bitmasking techniques</strong>.
          </li>
          <li style={{ marginBottom: '10px' }}>
            Reduced system load by <strong>50%</strong> using event-driven push/email notification
            systems.
          </li>
          <li style={{ marginBottom: '10px' }}>
            Improved <strong>CI/CD deployment speed by 90%</strong> through custom Gradle caching.
          </li>
        </ul>

        <br />

        <h2 style={{ color: '#222', borderBottom: '2px solid #ddd', paddingBottom: '5px', fontSize: '16px' }}>
          Side Project: LoveLog(current)
        </h2>
        <p style={{ fontSize: '16px', color: '#555', textAlign: 'justify' }}>
          I am also developing a personal side project,
          <a
            href="https://lovelog.xyz"
            target="_blank"
            style={{ color: '#0073e6', textDecoration: 'none', fontWeight: 'bold' }}
          >
            LoveLog
          </a>
          , a microservices-based platform incorporating <strong>SAGA pattern</strong>,
          <strong>hexagonal architecture</strong>, and <strong>event-driven design</strong>.
          If you want to see more of my work, please check below.
        </p>

        <br />

        <h2 style={{ color: '#222', borderBottom: '2px solid #ddd', paddingBottom: '5px', fontSize: '16px' }}>
          My Philosophy
        </h2>
        <p style={{ fontSize: '16px', color: '#555', textAlign: 'justify' }}>
          As a developer, I am passionate about <strong>continuous learning</strong> and
          <strong>problem-solving</strong>, striving to create value through{' '}
          <strong>collaboration</strong>
          &nbsp;and <strong>innovation</strong>.
        </p>
      </div>
      <TimeStampSection timestamps={timestamps} />
      <ProjectSection projects={projects} />
    </Layout>
  );
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        language
        author {
          name
          bio {
            role
            description
            thumbnail
          }
          social {
            github
            linkedIn
            email
          }
        }

        about {
          timestamps {
            date
            activity
            links {
              post
              github
              demo
              googlePlay
              appStore
            }
          }

          projects {
            title
            description
            techStack
            thumbnailUrl
            links {
              post
              github
              demo
              googlePlay
              appStore
            }
          }
        }
      }
    }
  }
`;
